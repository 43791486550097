<template>
  <b-container class="p-2">
    <b-row align-v="center">
      <b-col md="4" offset-md="4" class="text-right p-2">
        <b-link to="/login">
          Ya tengo una cuenta
        </b-link>
      </b-col>
      <b-col md="4" offset-md="4">
        <b-card>
          <h3 class="p-1 text-center">Completa tu registro</h3>
          <register-form
            :step="2"
            :token="token"
            @executeRegister="executeRegister"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
//Components
import RegisterForm from '@/components/RegisterForm'
//Bootsrap vue
import { BCol, BRow, BContainer, BLink, BCard } from 'bootstrap-vue'

export default {
  components: {
    RegisterForm,
    BCol,
    BRow,
    BContainer,
    BLink,
    BCard,
  },
  data() {
    return {
      token: this.$route.params.token,
    }
  },
  methods: {
    executeRegister(data) {
      console.log(data)
      localStorage.setItem('token', data.access_token)
      this.$store.dispatch('app/update_user', data.user)
      window.location = '/my-packages'
    },
  },
}
</script>
