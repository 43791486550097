var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storeClient)}}},[(_vm.props.step === 1)?_c('div',[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name"},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Apellido","label-for":"surname"}},[_c('ValidationProvider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","name":"surname"},model:{value:(_vm.client.surname),callback:function ($$v) {_vm.$set(_vm.client, "surname", $$v)},expression:"client.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('ValidationProvider',{attrs:{"name":"Correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email"},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_c('div',[_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","name":"password"},model:{value:(_vm.client.password),callback:function ($$v) {_vm.$set(_vm.client, "password", $$v)},expression:"client.password"}})],1),_c('b-form-group',{attrs:{"label":"Tipo de identificación","label-for":"address"}},[_c('ValidationProvider',{attrs:{"name":"Tipo de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.identificationTypes},model:{value:(_vm.client.identification_type),callback:function ($$v) {_vm.$set(_vm.client, "identification_type", $$v)},expression:"client.identification_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Número de identificacón","label-for":"identification_number"}},[_c('ValidationProvider',{attrs:{"name":"Número de identificación","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"identification_number","name":"identification_number"},model:{value:(_vm.client.identification_number),callback:function ($$v) {_vm.$set(_vm.client, "identification_number", $$v)},expression:"client.identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Provincia","label-for":"province"}},[_c('ValidationProvider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-key":"id","name":"province"},on:{"change":function($event){return _vm.getCantonsC()}},model:{value:(_vm.client.province),callback:function ($$v) {_vm.$set(_vm.client, "province", $$v)},expression:"client.province"}},_vm._l((_vm.provinces),function(province){return _c('b-form-select-option',{key:province.id,attrs:{"value":province}},[_vm._v(" "+_vm._s(province.name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Canton","label-for":"canton"}},[_c('ValidationProvider',{attrs:{"name":"canton","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-key":"id","name":"canton"},on:{"change":function($event){return _vm.getDistrictsC()}},model:{value:(_vm.client.canton),callback:function ($$v) {_vm.$set(_vm.client, "canton", $$v)},expression:"client.canton"}},_vm._l((_vm.cantons),function(c){return _c('b-form-select-option',{key:c.id,attrs:{"value":c}},[_vm._v(" "+_vm._s(c.name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Distrito","label-for":"district"}},[_c('ValidationProvider',{attrs:{"name":"Distrito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-key":"id","name":"district"},on:{"change":function($event){return _vm.getBarriosC()}},model:{value:(_vm.client.district),callback:function ($$v) {_vm.$set(_vm.client, "district", $$v)},expression:"client.district"}},_vm._l((_vm.districts),function(d){return _c('b-form-select-option',{key:d.id,attrs:{"value":d}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Barrio","label-for":"barrio"}},[_c('ValidationProvider',{attrs:{"name":"barrio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"value-key":"id","name":"barrio"},model:{value:(_vm.client.barrio),callback:function ($$v) {_vm.$set(_vm.client, "barrio", $$v)},expression:"client.barrio"}},_vm._l((_vm.barrios),function(b){return _c('b-form-select-option',{key:b.id,attrs:{"value":b}},[_vm._v(" "+_vm._s(b.name)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Dirección","label-for":"address"}},[_c('ValidationProvider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","name":"address"},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"phone"}},[_c('ValidationProvider',{attrs:{"name":"Teléfono","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","name":"phone"},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"LogInIcon","size":"16"}}),_vm._v(" Registrarme ")],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }