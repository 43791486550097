<template>
  <!-- form -->
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(storeClient)">
      <!-- code -->
      <!-- <b-form-group label="Código" label-for="code">
        <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
          <b-form-input id="code" v-model="client.code" name="code" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group> -->

      <div v-if="props.step === 1">
        <!-- name -->
        <b-form-group label="Nombre" label-for="name">
          <ValidationProvider
            name="Nombre"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-input id="name" v-model="client.name" name="name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <!-- surname -->
        <b-form-group label="Apellido" label-for="surname">
          <ValidationProvider
            name="Apellido"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-input
              id="surname"
              v-model="client.surname"
              name="surname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <!-- email -->
        <b-form-group label="Email" label-for="email">
          <ValidationProvider
            name="Correo"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-form-input id="email" v-model="client.email" name="email" />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </div>

      <div v-else>
        <!-- password -->
        <b-form-group label="Contraseña" label-for="password">
          <b-form-input
            id="password"
            v-model="client.password"
            type="password"
            name="password"
          />
        </b-form-group>
        <b-form-group label="Tipo de identificación" label-for="address">
          <ValidationProvider
            name="Tipo de identificación"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select
              v-model="client.identification_type"
              :options="identificationTypes"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <!-- identification_number -->
        <b-form-group
          label="Número de identificacón"
          label-for="identification_number"
        >
          <ValidationProvider
            name="Número de identificación"
            rules="required|integer"
            v-slot="{ errors }"
          >
            <b-form-input
              id="identification_number"
              v-model="client.identification_number"
              name="identification_number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="Provincia" label-for="province">
          <ValidationProvider
            name="Provincia"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select
              @change="getCantonsC()"
              value-key="id"
              v-model="client.province"
              name="province"
            >
              <b-form-select-option
                v-for="province in provinces"
                :value="province"
                :key="province.id"
              >
                {{ province.name }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="Canton" label-for="canton">
          <ValidationProvider
            name="canton"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select
              @change="getDistrictsC()"
              value-key="id"
              v-model="client.canton"
              name="canton"
            >
              <b-form-select-option v-for="c in cantons" :value="c" :key="c.id">
                {{ c.name }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="Distrito" label-for="district">
          <ValidationProvider
            name="Distrito"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select
              @change="getBarriosC()"
              value-key="id"
              v-model="client.district"
              name="district"
            >
              <b-form-select-option
                v-for="d in districts"
                :value="d"
                :key="d.id"
              >
                {{ d.name }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <b-form-group label="Barrio" label-for="barrio">
          <ValidationProvider
            name="barrio"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-select value-key="id" v-model="client.barrio" name="barrio">
              <b-form-select-option v-for="b in barrios" :value="b" :key="b.id">
                {{ b.name }}
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <!-- address -->
        <b-form-group label="Dirección" label-for="address">
          <ValidationProvider
            name="Dirección"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-input
              id="address"
              v-model="client.address"
              name="address"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>

        <!-- phone -->
        <b-form-group label="Teléfono" label-for="phone">
          <ValidationProvider
            name="Teléfono"
            rules="required|integer"
            v-slot="{ errors }"
          >
            <b-form-input id="phone" v-model="client.phone" name="phone" />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </div>

      <!-- submit button -->
      <div class="text-center">
        <b-button type="submit" variant="primary">
          <feather-icon icon="LogInIcon" size="16" /> Registrarme
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
//Vuex
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useActions, useState } = createNamespacedHelpers('app') // specific module name

//Composition api
import { ref, onMounted } from '@vue/composition-api'
//Services
import clientService from '@/services/client.service'
//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//Notification toast
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Bootsrap vue
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    step: {
      default: 1,
    },
    token: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    //Vuex
    const { getProvinces, getCantons, getDistricts, getBarrios } = useActions([
      'getProvinces',
      'getCantons',
      'getDistricts',
      'getBarrios',
    ])

    const { provinces, cantons, districts, barrios } = useState([
      'provinces',
      'cantons',
      'districts',
      'barrios',
    ])

    const baseClient = {
      name: '',
      surname: '',
      email: '',
      password: '',
      identification_type: '01',
      identification_number: '',
      address: '',
      phone: '',
      province: null,
      canton: null,
      district: null,
      barrio: null,
    }

    const client = ref(JSON.parse(JSON.stringify(baseClient)))
    const identificationTypes = ref([
      { value: '01', text: 'Cédula fisica' },
      { value: '02', text: 'Cédula juridica' },
      { value: '03', text: 'DIMEX' },
      { value: '04', text: 'NITE' },
    ])

    //METHODS
    const getProvincesC = () => {
      getProvinces({}).then(res => {
        client.value.province = res[0]
        getCantonsC()
      })
    }

    const getCantonsC = () => {
      console.log(client.value.province.number)
      getCantons({ province: client.value.province.number }).then(res => {
        client.value.canton = res[0]
        getDistrictsC()
      })
    }

    const getDistrictsC = () => {
      const province = client.value.province.number
      const canton = client.value.canton.number
      getDistricts({ province: province, canton: canton }).then(res => {
        client.value.district = res[0]
        getBarriosC()
      })
    }

    const getBarriosC = () => {
      const province = client.value.province.number
      const canton = client.value.canton.number
      const district = client.value.district.number
      getBarrios({
        province: province,
        canton: canton,
        district: district,
      }).then(res => {
        client.value.barrio = res[0]
      })
    }

    const storeClient = async () => {
      try {
        const { data: res } = await clientService.storeClientRegister({
          ...client.value,
          step: props.step,
          token: props.token,
        })

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
          return
        }
        client.value = JSON.parse(JSON.stringify(baseClient))
        emit('executeRegister', res.data)

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        emit('executeRegister', res.data)
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    onMounted(() => {
      getProvincesC()
    })

    return {
      storeClient,
      client,
      identificationTypes,
      props,
      provinces,
      cantons,
      districts,
      barrios,
      getProvincesC,
      getCantonsC,
      getDistrictsC,
      getBarriosC,
    }
  },
}
</script>

<style scoped></style>
